import React, { FC, useState } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Dialog } from 'wix-ui-tpa/Dialog';
import { Text } from 'wix-ui-tpa/Text';
import { RadioButton, RadioButtonTheme } from 'wix-ui-tpa/RadioButton';
import { RadioButtonGroup } from 'wix-ui-tpa/RadioButtonGroup';

import { classes, st } from './FieldPrivacyModal.st.css';
import {
  Field,
  FieldPrivacy,
  FieldType,
  ModalType,
} from '../../../../../types';
import { DataHook } from '../../../../../constants/DataHook';
import { useBi } from '../../../../../hooks/useBi';
import { getFieldLabel } from '../../common/Fields/utils/getFieldLabel';
import RadioButtonContent from './RadioButtonContent';

type Props = {
  field: Field;
  onChange(newPrivacy: FieldPrivacy): void;
};

const modalCloseDelay = 100;

export const FieldPrivacyModal: FC<ModalType<Props>> = ({
  onClose,
  isOpen,
  additionalProps,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const biLogger = useBi();

  const { field } = additionalProps;
  const initialIsFieldPrivate = field.privacy === FieldPrivacy.PRIVATE;

  const [isFieldPrivate, setIsFieldPrivate] = useState(initialIsFieldPrivate);

  const selectedOption = isFieldPrivate
    ? FieldPrivacy.PRIVATE.toString()
    : FieldPrivacy.PUBLIC.toString();
  const onChange = (newPrivacy: FieldPrivacy) => {
    const newIsFieldPrivate = newPrivacy === FieldPrivacy.PRIVATE;

    additionalProps.onChange(newPrivacy);
    setIsFieldPrivate(newIsFieldPrivate);
    biLogger.fieldPrivacyChanged(field.label, newPrivacy, field.type);
    setTimeout(() => onClose(), modalCloseDelay);
  };

  const subtitle =
    field.type === FieldType.ADDRESS
      ? field.value
          .map((subfield: Field) => getFieldLabel(t, subfield).trim())
          .join(', ')
      : '';

  const title = `${field.label} ${t('app.widget.modals.field-privacy.title')}`;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={st(classes.root, { mobile: isMobile })}
      wiredToSiteColors={false}
      data-hook={DataHook.FieldPrivacyModal}
    >
      <Text className={classes.title}>{title}</Text>
      <Text className={classes.subtitle}>
        {subtitle &&
          `${t('app.widget.modals.field-privacy.applies-to')}: ${subtitle}`}
      </Text>
      <div className={classes.boxSelectionContainer}>
        <RadioButtonGroup
          name={title}
          withSpacing
          theme={RadioButtonTheme.Box}
          value={selectedOption}
          onChange={onChange}
          className={classes.radioButtonGroup}
        >
          <RadioButton
            value={FieldPrivacy.PRIVATE.toString()}
            withFocusRing
            checked={!isFieldPrivate}
            theme={RadioButtonTheme.Box}
            className={classes.radioButton}
          >
            <RadioButtonContent
              label={t('app.widget.modals.field-privacy.private')}
              subLabel={t('app.widget.modals.field-privacy.private.sub-label')}
              fieldPrivacy={FieldPrivacy.PRIVATE}
            />
          </RadioButton>

          <RadioButton
            value={FieldPrivacy.PUBLIC.toString()}
            withFocusRing
            checked={isFieldPrivate}
            theme={RadioButtonTheme.Box}
            className={classes.radioButton}
          >
            <RadioButtonContent
              label={t('app.widget.modals.field-privacy.public')}
              subLabel={t('app.widget.modals.field-privacy.public.sub-label')}
              fieldPrivacy={FieldPrivacy.PUBLIC}
            />
          </RadioButton>
        </RadioButtonGroup>
      </div>
    </Dialog>
  );
};
