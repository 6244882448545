import { TFunction } from '@wix/yoshi-flow-editor';
import { Field } from '../../../../../../types';

const hasTranslation = (t: TFunction, key: string) => t(key) === key;

export const getFieldLabel = (
  t: TFunction,
  { key, label, index }: Pick<Field, 'label' | 'key' | 'index'>,
) =>
  `${hasTranslation(t, key) && label ? label : t(key)} ${
    index ? index + 1 : ''
  }`;
